@import './../../variables.scss';

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: background 0.8s;
  height: 70px;

  &:before {
    @include pseudo_abs();
    background-color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    top: -100%;
    transition: top 0.3s, box-shadow 0.3s;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  &.scrolled_header {
    backdrop-filter: blur(2px);

    &::before {
      top: 0;
      transition: top 0.8s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .content_wrap {
      height: 70px;

      .links_wrap {
        .main_link {
          color: #000;

          &:before {
            background-color: #000;
          }
        }
      }
    }

    .logo_wrap {
      // svg {
      //   path {
      //     fill: #000;

      //     &.icon_logo {
      //       fill: #a459ff;
      //     }
      //   }
      // }
    }
  }

  &.black_version {
    .content_wrap {
      .links_wrap {
        .main_link {
          color: #000;

          &:before {
            background-color: #000;
          }
        }
      }
    }

    .logo_wrap {
      svg {
        path {
          fill: #000;

          &.icon_logo {
            fill: #a459ff;
          }
        }
      }
    }

    &.scrolled_header {
      .content_wrap {
        .main_link {
          text-shadow: none;
        }

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.black_white_version {
    .content_wrap {
      .links_wrap {
        .main_link {
          color: #fff;

          &:before {
            background-color: #fff;
          }
        }
      }
    }
  }

  .content_wrap {
    height: $mainnav-height;
    @include flex-row();
    justify-content: space-between;
    margin-bottom: 0;
    transition: height 0.8s, box-shadow 0.8s;
    max-width: 1650px;

    .links_wrap {
      @include flex-row();
      justify-content: flex-end;

      .sub_links_wrap {
        display: none;
      }

      .main_link {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.5px;
        color: #fff;
        text-decoration: none !important;
        margin-right: 65px;
        position: relative;
        cursor: pointer;
        @include flex-column();
        transition: color 0.3s;

        &:hover {
          &:before {
            width: 100%;
          }
        }

        &:before {
          @include pseudo_abs();
          height: 2px;
          width: 100%;
          background: #fff;
          top: auto;
          bottom: -5px;
          left: 50%;
          transform: translate(-50%);
          width: 0%;
          transition: width 0.3s;
        }
      }

      .buttons_wrap {
        a {
          &:not(:first-child) {
            margin-left: 24px;
          }
        }
      }
    }

    .logo_wrap {
      cursor: pointer;

      svg {
        path {
          transition: fill 0.3s;
        }
      }
    }

    .hamburger {
      @include flex-row();
      display: none;
      height: 32px;
      width: 32px;
      padding: 7px;

      svg {
        width: 18px;
        height: 18px;
        animation: zoomIn 0.7s 1;
      }
    }
  }

  .mega_menu {
    @include flex-row();
    gap: 70px;
    padding: 0 15px;
    transition: max-height 0.2s, padding 0.3s, min-height 0.2s, opacity 0.3s;
    background-color: #000;
    width: 100%;
    // background-image: url('../../assets/svg/icons/LogoPlaced.svg');
    background-position: center;
    background-size: contain;
    // background-attachment: fixed;
    background-repeat: no-repeat;
    box-sizing: border-box;
    align-items: flex-start;
    overflow: hidden;
    max-height: 0vh;
    min-height: 0px;
    position: relative;
    opacity: 0;

    &.visible {
      padding-top: 48px;
      padding-bottom: 60px;
      max-height: 100vh;
      min-height: 300px;
      opacity: 1;

      .logo_placed {
        svg {
          animation: fadeIn 1.2s 1;
        }
      }
    }

    .logo_placed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include flex-row();
      z-index: 0;

      svg {
        animation-delay: 400ms;
      }
    }

    .link_card {
      text-decoration: none !important;
      max-width: 215px;
      width: 100%;
      @include flex-column();
      z-index: 2;

      .image_wrap {
        @include flex-column();
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
        height: 114px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.4s;
          object-position: center;
        }
      }

      .info_wrap {
        margin-top: 13px;
        @include flex-row();
        width: 100%;
        align-items: flex-start;

        .texts_wrap {
          @include flex-column();
          align-items: flex-start;
          justify-content: space-between;
          flex: 1;

          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: #ffffff;
          }

          .sub_text {
            margin-top: 8px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.5px;
            color: #b2b2b2;
            opacity: 0.7;
          }
        }

        .arrow_right {
          margin-right: 3px;
          transition: margin-right 0.3s;
        }
      }

      &:hover {
        .image_wrap {
          img {
            transform: scale(1.1);
          }
        }

        .info_wrap {
          .arrow_right {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  header {
    .content_wrap {
      padding: 0 24px;
      height: $mainnav-mob-height;

      .sub_links_wrap {
        @include flex-column();
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 20px;
      }

      .links_wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #000;
        margin-top: $mainnav-mob-height;
        flex-direction: column;
        justify-content: flex-start;
        height: 0;
        transition: height 0.8s, padding 0.8s;
        overflow: hidden;
        padding: 0px 30px;

        &.is_mob_visible {
          height: calc(100vh - $mainnav-mob-height);
          padding: 40px 30px;

          .main_link,
          .sub_link,
          .buttons_wrap {
            opacity: 1;
          }
        }

        .buttons_wrap {
          order: 1;
          width: 100%;
          @include flex-row();
          margin-bottom: 40px;
          transition: opacity 1s;
          opacity: 0;

          a {
            flex: 1;
            min-width: calc(50% - 8px);

            &:not(:first-child) {
              margin-left: 16px;
            }

            button {
              width: 100%;
            }
          }
        }

        .main_link,
        .sub_link {
          order: 2;
          margin: 0;
          width: 100%;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -2px;
          font-feature-settings: 'ordn' on;
          text-align: left;
          margin-bottom: 50px;
          align-items: flex-start;
          transition: opacity 1s;
          opacity: 0;
          color: #fff !important;
          -webkit-tap-highlight-color: transparent;

          &:before {
            display: none;
          }

          &.greyed {
            color: #b2b2b2 !important;
          }
        }

        .sub_link {
          margin-bottom: 24px;
          color: #fff;
          text-decoration: none !important;
        }
      }

      .hamburger {
        display: flex;
      }
    }
  }
}
