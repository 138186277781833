@import './variables.scss';

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
  }
}

@keyframes heightAllIncrease {
  from {
    line-height: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    overflow: hidden;
  }
}

@keyframes zoomInPop {
  from {
    // opacity: 0;
    transform: scale(0.8);
    border-radius: 50%;
  }
  to {
    opacity: 1;
    border-radius: 24px;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes heightExpand {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

@keyframes heightExpandMax {
  from {
    height: 0%;
    max-height: 0%;
  }
  to {
    height: 100%;
    max-height: 100%;
  }
}

@keyframes overlayAnimation {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes slideLtR {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideLtRBig {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(50px);
  }
}

@keyframes slideTtB {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateYX(0px);
  }
}

@keyframes slideFullTtB {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateYX(0%);
  }
}

@keyframes slideBtT {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateYX(0px);
  }
}

@keyframes slideRtL {
  from {
    transform: translateX(10px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes slideRtLbutton {
  from {
    opacity: 0;
    transform: translateX(80px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes slideRtLFull {
  from {
    transform: translateX(400px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes gentleWave {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes gentleWaveXed {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(20px);
  }
}

@keyframes bigWave {
  from {
    transform: translateY(-180px);
  }
  to {
    transform: translateY(180px);
  }
}

@keyframes lightOpacity {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes notificationAnime {
  from {
    max-width: 0%;
    max-height: 0%;
    opacity: 0;
  }

  to {
    max-width: 100%;
    opacity: 1;
    // max-height: 100%;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    transform: translate(0, 15px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes parallaxFadeUp {
  from {
    top: 20px;
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  85% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes cardAnime {
  0% {
    transform: rotateY(0deg) scale(0.1) translate(205%, 200%);
  }
  50% {
    transform: rotateY(0deg) scale(1) translate(0, 0);
  }
  60% {
    transform: rotateY(0deg);
  }
  65% {
    transform: rotateY(90deg);
  }
  70% {
    transform: rotateY(180deg);
  }
  75% {
    transform: rotateY(270deg);
  }
  80% {
    transform: rotateY(360deg);
  }
  85% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg) scale(1) translate(0, 0);
  }
}

@keyframes widthFromLeft {
  from {
    width: 0%;
  }
}
@keyframes widthIncrease {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes backgroundColor {
  from {
    background-color: transparent;
  }
}

@keyframes bgZoomIn {
  from {
    background-size: 140%;
  }
  to {
    background-size: 100%;
  }
}

@keyframes bgZoomInAuto {
  from {
    background-size: auto 140%;
  }
  to {
    background-size: auto 100%;
  }
}

@keyframes bgImageZoomIn {
  from {
    height: 200%;
    width: 200%;
    // margin-left: -50%;
  }
  to {
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
}
@keyframes zoomInMobile {
  from {
    background-position: 0%;
  }
  to {
    background-position: 50%;
  }
}

@keyframes zoomInMobileLefty {
  from {
    background-position: 0%;
  }
  to {
    background-position: 70%;
  }
}
