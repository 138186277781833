// @import './assets/font/stylesheet.css';
@import url('https://use.typekit.net/vft5rcz.css');
@import './variables.scss';
@import './animations.scss';

html {
  scroll-behavior: smooth;
  background: #fff;
  font-family: 'Indivisible' !important;

  &.overflow_hidden {
    overflow: hidden;
  }
}

body {
  margin: 0;
  font-family: 'Indivisible', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  * {
    box-sizing: border-box;
  }

  #root {
    background-color: #fff;
  }
}

header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .content_wrap {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.content_wrap {
  width: 100%;
  max-width: 1400px;
  margin-left: 50%;
  transform: translate(-50%);
  padding: 0 30px;
  box-sizing: border-box;
}

.page_wrapper {
  padding-top: $mainnav-height;
  overflow: hidden;
}

.error_text {
  color: #f51818;
  font-size: 13px;
  margin-top: 4px;
}

.flex_box {
  @include flex-row();

  &.space_between {
    justify-content: space-between;
  }

  &.align_start {
    align-items: flex-start;
  }
  &.justify_start {
    justify-content: flex-start;
  }

  &.flex_wrap {
    flex-wrap: wrap;
  }

  &_column {
    @include flex-column();
    align-items: flex-start;
  }

  .x2 {
    z-index: 1;
    width: 50%;
    @include flex-row();

    &.adjust_padding {
      width: calc(50% - 7.5px);
    }

    &.align_start {
      align-items: flex-start;
    }

    &.align_end {
      align-items: flex-end;
    }

    &.justify_start {
      justify-content: flex-start;
    }

    &.justify_end {
      justify-content: flex-end;
    }

    &.flex_column {
      flex-direction: column;
    }
  }

  .x3 {
    width: calc(33.33% - 10px);
  }

  .x4 {
    width: calc(50% - 12px);
  }
}

.full_width {
  max-width: 100% !important;
  width: 100% !important;
}

button,
.button {
  font-family: 'Indivisible', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  background: #ffffff;
  text-decoration: none;
  border: none;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  padding: 12px 20px;
  letter-spacing: -0.5px;
  color: #000;
  font-size: 16px;
  line-height: 17px;
  cursor: pointer;
  transition: background 0.3s, transform 0.1s, opacity 0.3s;
  transition-timing-function: ease-in-out;
  border: 1px solid #ebebeb;
  text-align: center;
  opacity: 1;

  &[disabled] {
    cursor: default;
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover:not(disabled) {
    background-color: rgba(255, 255, 255, 0.9);

    &:active {
      transform: scale(0.95);
    }
  }

  &.small {
    padding: 8px 12px;
    font-size: 13px;
    line-height: 16px;
  }

  &.colored {
    background-image: linear-gradient(
      279.95deg,
      #624ce0 10.56%,
      #a459ff 82.49%
    ) !important;
    color: #fff;
    background-size: calc(100% + 30px);
    border-color: #624ce0;

    &:hover {
      background-position: -30px !important;
    }
  }

  &.loader_button {
    @include flex-row();
    flex-wrap: nowrap;
    white-space: nowrap;

    &[disabled] {
      background-color: #ebebeb;
    }

    svg {
      margin-right: 5px;
      animation: rotate 0.8s infinite linear;
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -2px;
  font-feature-settings: 'ordn' on;
  margin: 0;
  margin-bottom: 24px;
}

h2 {
  font-family: 'Indivisible';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -1px;
  font-feature-settings: 'ordn' on;
}

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.title_text {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: -2px;
}

.common_title {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1px;
  color: #000000;
}

.sub_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #000000;
}

p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin: 0;

  &.bigger {
    font-size: 18px;
    line-height: 22px;
  }

  &.larger {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -2px;
  }

  &.smaller {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  &.tiny {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.5px;
  }

  &.para {
    font-size: 14px;
    line-height: 16px;
  }
}

.white_text {
  color: #ffffff;
}

.grey_text {
  color: #b2b2b2;
}

.placholder_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #b2b2b2;
}

.normal_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1px;
  margin-bottom: 16px;
}

.small_text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #000000;
}

.hidden {
  display: none;
}

.small_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.circle_info_title {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -2px;
  font-feature-settings: 'ordn' on;
  color: #000000;
  margin-bottom: 32px;
}

.text_box_wrap {
  @include flex-column();
  align-items: flex-start;

  &.x2_column {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .text_box {
      width: calc(50% - 50px);
      padding-top: 40px !important;

      &:nth-last-child(-n + 2) {
        border-bottom: 0;
      }
    }
  }

  &.x3_column {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .text_box {
      width: calc(33.33% - 50px);
      padding-top: 40px !important;
      border-bottom: none;
      width: 100%;
      max-width: 310px;
    }
  }

  .text_box {
    border-bottom: 4px solid #ebebeb;
    padding: 40px 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) and (orientation: portrait) {
  html {
    body {
      .circle_info_title {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -2px;
        margin-bottom: 16px;
        text-align: left;
      }

      .normal_heading {
        text-align: left;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -2px;
      }

      .text_box_wrap {
        .text_box {
          border-bottom: 0;
        }
      }

      h1 {
        font-size: 32px;
        line-height: 39px;
        letter-spacing: -2px;
      }

      p {
        font-size: 14px;
      }

      .flex_box:not(.response_stays) {
        flex-direction: column;

        .x2 {
          width: 100%;
        }
      }
    }
  }
}
