@import './../../variables.scss';
$circle-size: min(90vh, 800px);

.coming_soon_wrap {
  min-height: 100vh;

  .bg_image {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 150vh;
    object-fit: cover;
    top: 70px;
  }

  .hero_image_wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $circle-size;

    .phone_image {
      position: absolute;
      left: -33%;
      top: 0;
      position: relative;
      z-index: 3;
      height: min(72vh, 502px);
      // animation: gentleWave 3s infinite alternate;
    }

    .circle_wrap {
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;

      .circle {
        background-color: #62d1a4;
        width: 100%;
        height: 100%;
        height: $circle-size;
        width: $circle-size;
        background-color: #62d1a4;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background-image: url('../../assets/images/house.png');
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 50%;
          height: $circle-size;
        }
      }

      // .house_image {
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   border-bottom-left-radius: 50%;
      //   border-bottom-right-radius: 50%;
      //   width: $circle-size;
      //   height: $circle-size;
      //   object-fit: contain;
      //   object-position: left bottom;
      //   // display: none;
      // }
    }
  }

  .main_hero_section {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin-top: 30px;

    .main_wrap {
      width: 100vw;
      max-width: 1400px;

      .first_section {
        z-index: 2;
        position: relative;
      }
    }

    .main_heading {
      font-family: 'Indivisible';
      font-style: normal;
      font-weight: 700;
      font-size: 112px;
      line-height: 116px;
      display: flex;
      align-items: center;
      letter-spacing: -4.5px;
      color: #000000;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      max-width: 600px;
    }

    .sub_heading {
      margin-top: 32px;
      font-family: 'Indivisible';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      align-items: center;
      letter-spacing: -1px;
      color: #000000;
      max-width: 533px;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .coming_soon_section {
    margin-top: 80px;
    padding-top: 60px;
    overflow: hidden;
    margin-bottom: 60px;

    .coming_soon_text {
      position: relative;
      font-family: 'Indivisible';
      font-style: normal;
      font-weight: 700;
      font-size: 112px;
      line-height: 116px;
      display: flex;
      align-items: center;
      letter-spacing: -4.5px;
      color: #000000;
      padding-top: 20px;

      &:before {
        content: 'Coming Soon';
        position: absolute;
        font-family: 'Indivisible';
        font-style: normal;
        font-weight: 700;
        font-size: 17pc;
        line-height: 116px;
        display: flex;
        align-items: center;
        letter-spacing: -4.5px;
        color: #f3f3f3;
        z-index: -1;
        white-space: nowrap;
        left: -17%;
        top: 10px;
        text-align: center;
        width: 100vw;
      }
    }

    .notify_wrap {
      margin-top: 50px;

      .notify_text {
        font-family: 'Indivisible';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        display: flex;
        align-items: center;
        letter-spacing: -1px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .input_wrap {
        margin-top: 22px;
        display: flex;
        justify-content: flex-start;

        input {
          background: #f7f7f7;
          border: 1px solid #ebebeb;
          border-radius: 4px;
          flex: none;
          order: 0;
          flex-grow: 0;
          width: 480px;
          height: 77px;
          font-family: 'Indivisible';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: center;
          letter-spacing: -1px;
          color: #000000;
          padding: 24px;
          margin-right: 25px;
        }

        button {
          padding: 22px 24px;
          background: #a459ff;
          border-bottom: 8px solid #202d59 !important;
          border-radius: 4px;
          border: none;
          font-family: 'Indivisible';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.5px;
          color: #ffffff;

          &[disabled] {
            opacity: 0.7;
          }
        }
      }
    }
  }

  footer {
    margin-top: 180px;
    padding-bottom: 50px;

    .copyright {
      font-family: 'Indivisible';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #667085;
      margin-top: 64px;
    }

    .battalion_named {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .text {
        margin-top: 16px;
        font-family: 'Indivisible';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: #475467;
      }
    }
    .contact_us {
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;

      .contact_title {
        font-family: 'Indivisible';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-align: right;
        color: #667085;
      }

      a {
        font-family: 'Indivisible';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #a459ff;
        text-decoration: none !important;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .coming_soon_wrap {
    .hero_image_wrap {
      height: 430px;
      width: 100%;

      .circle_wrap {
        .circle {
          height: 430px;
          width: 430px;

          &:before {
            height: 430px;
          }
        }
      }

      .phone_image {
        height: min(45vh, 360px);
        left: 0%;
        top: -12%;
      }
    }

    .main_hero_section {
      .first_section {
        min-height: 40vh;

        .main_heading {
          font-size: 55px;
          line-height: 1;
          max-width: 340px;
          // text-align: center;
        }

        .sub_heading {
          font-size: 25px;
          line-height: 1;
          max-width: 280px;
          // text-align: center;
        }
      }
    }

    .coming_soon_section {
      .coming_soon_text {
        font-size: 55px;
        line-height: 1;
        word-wrap: initial;
        padding-top: 41px;
        // text-align: center;
        max-width: 252px;
        text-align: left;

        &:before {
          font-size: 5pc;
          white-space: initial;
          line-height: 1;
        }
      }

      .notify_wrap {
        .notify_text {
          font-size: 25px;
          line-height: 1;
        }

        .input_wrap {
          flex-direction: column;

          input,
          button {
            width: 100%;
            padding: 15px 12px;
            height: auto;
          }

          input {
            background: #ffffff;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            border: 1px solid #d0d5dd;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 12px 14px;
          }

          button {
            margin-top: 15px;
            text-align: center;
            justify-content: center;
            max-width: 88px;
            font-size: 12px;
            border-bottom: 4px solid #202d59 !important;
            border-radius: 8px;
            padding: 8px 14px 8px 14px;
          }
        }
      }
    }

    footer {
      .battalion_named {
        width: 100%;
        margin-bottom: 30px;

        .text {
          font-size: 16px;
          margin-top: 12px;
        }
      }

      .contact_us {
        width: 100%;
        align-items: flex-start;

        .contact_title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }

        a {
          font-size: 16px;
          margin-top: 8px;
        }
      }

      .copyright {
        font-size: 14px;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  html {
    overflow-x: hidden;

    .coming_soon_wrap {
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 1024px) and (min-height: 7500px) {
  .coming_soon_wrap {
    .hero_image_wrap {
      opacity: 0.4;

      .circle_wrap {
        .circle {
          height: 430px;
          width: 430px;

          &:before {
            height: 430px;
          }
        }
      }

      .phone_image {
        height: min(45vh, 227px);
        left: -7%;
        top: -24%;
      }
    }
  }
}
@media all and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) and (orientation: portrait) {
  .main_hero_section {
    .main_wrap {
      flex-direction: row !important;

      .x2 {
        width: 50% !important;
      }

      .hero_image_wrap {
        height: auto;

        .phone_image {
        }
      }
    }
  }
}
